import React from 'react';
import { Nav } from 'react-bootstrap';
import './_nav.scss';

interface IProps {
    resetNavPosition?: boolean;
}

function NavBarBottom({ resetNavPosition }: Readonly<IProps>) {
    return (
        <div
            className={`navPosition ${resetNavPosition ? 'resetPosition' : ''}`}
        >
            <div className="bg-nav-color">
                <Nav className="d-flex text-center">
                    <Nav.Link href="tel:800 238 2727" className="flex-fill">
                        Call ADT
                    </Nav.Link>
                    <Nav.Link
                        href="https://www.adt.com/about-adt/legal/adt-control-terms-of-use"
                        target="_blank"
                        className="flex-fill"
                    >
                        Terms of Service
                    </Nav.Link>
                    <Nav.Link
                        href="https://help.adt.com/s/article/Alarm-MessengerTM-FAQs"
                        target="_blank"
                        className="flex-fill"
                    >
                        FAQ
                    </Nav.Link>
                    <Nav.Link
                        href="https://www.adt.com/about-adt/legal/privacy-policy"
                        target="_blank"
                        className="flex-fill"
                    >
                        Privacy Policy
                    </Nav.Link>
                </Nav>
            </div>
        </div>
    );
}

export default NavBarBottom;
